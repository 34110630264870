import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../components/Layout/context";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import { useStaticQuery, graphql } from "gatsby";
import slugify from "slugify";
import { useLocation } from "@reach/router";
import SEO from "../../components/SEO";
import blurImage from "../../images/138-1384611_black-blue-blur-background.jpg";
const BlogDetail = () => {
  const location = useLocation();
  const slug = location.pathname
    .replace("/explore/", "")
    .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");

  const { lan, setLang } = useContext(AppContext);

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allStrapiPost {
        edges {
          node {
            locale
            id
            title
            slug
            body
            short_description
            author
            date(formatString: "DD-MM-YYYY")
            image {
              url
              alternativeText
            }
          }
        }
      }
    }
  `);
  const posts = data.allStrapiPost?.edges;
  const filteredData = posts.filter(({ node }) => node.locale.includes(lan));

  const filteredPost = filteredData.filter(({ node }) =>
    slugify(node.slug, {
      lower: true,
    }).includes(slug)
  );

  return (
    <div className="px-4 md:px-0">
      <SEO
        title={`Happen Space | ${filteredPost[0]?.node?.title}`}
        image={filteredPost[0]?.node?.image?.url}
        description={filteredPost[0]?.node?.short_description}
        pathname={location.pathname}
      />
      <h1 className="max-w-5xl py-16 mx-auto text-2xl font-semibold text-white md:text-5xl">
        {filteredPost[0]?.node?.title}
      </h1>
      <div>
        <img
          src={
            filteredPost[0]?.node?.image?.url !== undefined
              ? filteredPost[0]?.node?.image?.url
              : blurImage
          }
          alt=""
          className="w-full md:h-[60vh] object-cover"
        />
      </div>
      <div className="max-w-6xl px-4 pt-8 mx-auto">
        <div className="my-12 blog-detail md:px-10">
          <ReactMarkdown
            children={filteredPost[0]?.node?.body}
            remarkPlugins={[remarkGfm, remarkMath, remarkGemoji]}
            rehypePlugins={[rehypeRaw]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={dark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default BlogDetail;
