import React from "react";
import BlogDetail from "../../views/BlogDetail";
import Layout from "../../components/Layout/Layout";
import SignUp from "../../views/Home/SignUp";
const Article = () => {
  return (
    <>
      <div className="bg-bgBlack">
        <Layout>
          <BlogDetail />
          <div id="Signup">
            <SignUp />
          </div>
        </Layout>
      </div>
    </>
  );
};

export default Article;
